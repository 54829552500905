import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import "./Loading.css";

import AuthService from "./service/AuthService";
import Home from "./components/page/Home";
import Login from "./components/page/auth/Login";
import Signup from "./components/page/auth/Signup";
import ScanService from "./service/ScanService";
import ForgotPassword from "./components/page/auth/ForgotPassword";
import ConfirmPassword from "./components/page/auth/ConfirmPassword";
import AuthLayout from "./components/page/auth/AuthLayout";
import ListScan from "./components/page/scan/list";
import AppLayout from "./components/layout/Layout";
import VerifyAccount from "./components/page/auth/VerifyAccount";
import Buy from "./components/page/payment/Buy";
import Success from "./components/page/payment/Success";
import SubscriptionService from "./service/SubscriptionService";
import ReportService from "./service/ReportService";
import Account from "./components/page/account/Account";
import NewScan from "./components/page/scan/new";
import Landing from "./components/page/Landing";
import TermsOfService from "./components/page/TermsOfService";
import PrivacyPolicy from "./components/page/PrivacyPolicy";
import DetailScan from "./components/page/scan/detail";
import context from "./exports.json";

const auth = new AuthService(
  context.CognitoRegion,
  context.CognitoPoolId,
  context.CognitoClientId
);

const scanService = new ScanService(auth, `${context.ApiGatewayUrl}/api`);

const subscriptionService = new SubscriptionService(
  `${context.ApiGatewayUrl}/api`
);

const reportService = new ReportService(auth, `${context.ApiGatewayUrl}/api`);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/" element={<Landing />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route element={<AuthLayout authService={auth} />}>
        <Route path="login" element={<Login authService={auth} />} />
        <Route path="signup" element={<Signup authService={auth} />} />
        <Route
          path="forgot-password"
          element={<ForgotPassword authService={auth} />}
        />
        <Route
          path="confirm-password"
          element={<ConfirmPassword authService={auth} />}
        />
        <Route
          path="verify-account"
          element={<VerifyAccount authService={auth} />}
        />
      </Route>
      <Route element={<AppLayout authService={auth} />}>
        <Route
          path="scan/new"
          element={<NewScan authService={auth} scanService={scanService} />}
        />
        <Route path="scan" element={<ListScan scanService={scanService} />} />
        <Route
          path="scan/:id"
          element={
            <DetailScan
              scanService={scanService}
              reportService={reportService}
            />
          }
        />
        {/* <Route path="confirm" element={<Confirm authService={auth} />} /> */}
        <Route
          path="home"
          element={<Home authService={auth} scanService={scanService} />}
        />
        <Route
          path="account"
          element={
            <Account
              authService={auth}
              subscriptionService={subscriptionService}
            />
          }
        />
      </Route>
      <Route
        path="payment/buy"
        element={
          <Buy
            buyService={{ url: "http://localhost:9999/api" }}
            authService={auth}
          />
        }
      />
      <Route
        path="payment/success"
        element={
          <Success
            buyService={{ url: "http://localhost:9999/api" }}
            authService={auth}
          />
        }
      />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
