import { useEffect, useState } from "react";
import ScanService from "../../../service/ScanService";
import { useParams } from "react-router-dom";
import React from "react";
import { CodeScanPayload, InfraScanPayload, ReportItem, Scan } from "common";
import ReportService from "../../../service/ReportService";
import BreadCrumbs from "../../Breadcrumbs";
import { mdiCheck, mdiCubeScan, mdiHome } from "@mdi/js";
import Badge from "../../Badge";
import { TabPicker } from "../../Tab";
import ReportItemDetailModal from "./tabs/ReportItemDetailModal";
import InfrastructureTab from "./tabs/InfrastructureTab";
import CodeScanTab from "./tabs/CodeScanTab";
import ComplianceTab from "./tabs/ComplianceTab";
import Loading from "../../Loading";
import ExternalScanTab from "./tabs/ExternalScanTab";
import { colorCodes } from "../../../ColorCodes";

interface Props {
  scanService: ScanService;
  reportService: ReportService;
}

export default function DetailScan({ scanService, reportService }: Props) {
  let { id } = useParams();

  const [tab, setTab] = useState("");
  const [options, setOptions] = useState([] as string[]);
  const [report, setReport] = useState(undefined as unknown as ReportItem[]);
  const [scan, setScan] = useState(undefined as unknown as Scan);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [previousButtonDisabled, setPreviousButtonDisabled] = useState(true);
  const [severityFilter, setSeverityFilter] = useState(
    undefined as undefined | string
  );
  const [scanFilter, setScanFilter] = useState("Infrastructure");
  const [selectedReportItem, setSelectedReportItem] = useState(
    undefined as ReportItem | undefined
  );

  const loadReports = async (
    id: number,
    lastToken?: string,
    paginateBackwards?: boolean,
    severity?: string,
    scanType?: string
  ) => {
    const report = await reportService.getItems(
      id,
      lastToken,
      paginateBackwards,
      { severity: severity, scanType: scanType ?? scanFilter }
    );
    setReport(report);
  };

  const loadScan = async (id: number) => {
    const scanResponse = await scanService.get(id);
    setScan(scanResponse);
    populateTabOptions(scanResponse);
  };

  const downloadComplianceReport = async (
    frameworkId: number,
    targetId: number
  ) => {
    reportService.downloadComplianceReport(
      parseInt(id as string),
      targetId,
      frameworkId
    );
  };

  const downloadCodescanReport = async (targetId: number) => {
    reportService.downloadCodescanReport(parseInt(id as string), targetId);
  };

  const downloadReport = async () => {
    await reportService.get(parseInt(id as string));
  };

  const onNextClick = async (last: ReportItem) => {
    setPreviousButtonDisabled(false);
    const reportItems = await reportService.getItems(
      Number(id),
      last.Id,
      false,
      { severity: severityFilter, scanType: scanFilter }
    );
    if (!reportItems.length) {
      setNextButtonDisabled(true);
      return;
    }

    setReport(reportItems);
  };

  const onPreviousClick = async (last: ReportItem) => {
    setNextButtonDisabled(false);
    const reportItems = await reportService.getItems(
      Number(id),
      last.Id,
      true,
      { severity: severityFilter }
    );
    if (!reportItems.length) {
      setPreviousButtonDisabled(true);
      return;
    }

    setReport(reportItems);
  };

  const onSeverityClick = async (s: any) => {
    if (s === severityFilter) {
      setSeverityFilter(undefined);
      loadReports(Number(id), undefined, undefined);
    } else {
      setSeverityFilter(s);
      loadReports(Number(id), undefined, undefined, s);
    }
  };
  
  const onEnrichRemediationWithAi = async (reportItem: ReportItem) => {
    reportService.enrichRemediationWithAi(reportItem);
  }

  useEffect(() => {
    if (report === undefined) loadReports(Number(id));
    if (scan === undefined) loadScan(Number(id));
  }, [id]);

  if (!scan || !report) {
    return <Loading />;
  }

  function populateTabOptions(scan: Scan) {
    const options: any[] = [];
    if (scan.Targets.find((t) => t.Type === "InfraScan")) {
      options.push("Infrastructure");
      options.push("Compliance");
      options.push("External Scan");
    }

    if (scan.Targets.find((t) => t.Type === "CodeScan")) {
      options.push("Code Scan");
    }
    setTab(options[0]);
    setOptions(options);
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-2 max-h-64">
        <div className="pt-10">
          {scan && (
            <>
              <BreadCrumbs
                items={[
                  { iconPath: mdiHome, text: "home", link: "/home" },

                  { text: "Scan", link: "/scan" },
                  {
                    text: scan.ReportId.toString(),
                    link: "/scan/" + scan.ReportId,
                  },
                ]}
              />

              {selectedReportItem && (
                <ReportItemDetailModal
                onEnrichRemediationWithAi={(reportItem: ReportItem) => onEnrichRemediationWithAi(reportItem)}
                  reportItem={selectedReportItem}
                  buttonText="Go Back"
                  onClick={() => setSelectedReportItem(undefined)}
                />
              )}

              <h2 className="text-gray-300 text-xl mt-8 mb-4">
                Scanned {scan.Targets.length} targets
              </h2>
              <span className="space-x-4">
                {scan.Targets.map(
                  (target: InfraScanPayload | CodeScanPayload) => {
                    return (
                      <Badge
                        borderColor="border-indigo-400"
                        backgroundColor="bg-gray-900"
                        textColor="text-indigo-400"
                        className="rounded"
                        text={target.Type}
                        iconPath={
                          target.Type === "InfraScan" ? mdiCubeScan : mdiCheck
                        }
                      />
                    );
                  }
                )}
              </span>
              <div className="mb-8" />
            </>
          )}
        </div>
        <div className="text-white">
          <div className="relative overflow-x-auto m-20">
            <h2 className="text-gray-400 text-lg pb-4">Identified Issues</h2>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {Object.keys(colorCodes).map((c) => {
                    return (
                      <td className={`px-6 py-3 ${colorCodes[c].textColor}`}>
                        {c}
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  {scan.Digest &&
                    Object.keys(colorCodes).map((colorKey: string) => {
                      return (
                        <>
                          <td className="px-6 py-4">{scan.Digest[colorKey]}</td>
                        </>
                      );
                    })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <p className="text-gray-300 pb-10">
                    Executive Summary from Claude
      </p> */}

      <TabPicker
        options={options}
        firstSelectedByDefault={true}
        onChange={(tab) => {
          if (tab === "Infrastructure") {
            setReport([]);
            setSeverityFilter(undefined)
            setScanFilter("Infrastructure");
            loadReports(
              parseInt(id as string),
              undefined,
              undefined,
              severityFilter,
              "Infrastructure"
            );
          } else if (tab === "External Scan") {
            setReport([]);
            setScanFilter("Network");

            loadReports(
              parseInt(id as string),
              undefined,
              undefined,
              severityFilter,
              "Network"
            );
          }
          setTab(tab);
        }}
      />
      <div className="" />
      {report && scan && tab === "Infrastructure" && (
        <>
          <InfrastructureTab
            downloadReport={downloadReport}
            scan={scan}
            reportItems={report}
            selectedSeverity={severityFilter}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
            onSeverityClick={onSeverityClick}
            previousButtonDisabled={previousButtonDisabled}
            nextButtonDisabled={nextButtonDisabled}
            onFocusReportItem={(reportItem: ReportItem) =>
              setSelectedReportItem(reportItem)
            }
          />
        </>
      )}
      {report &&
        tab === "Compliance" &&
        scan.Targets.map((target, index) => {
          if (target.Type === "InfraScan")
            return (
              <ComplianceTab
                scan={scan}
                title={`Target ${index + 1}`}
                onFrameworkClick={(complianceId) => {
                  downloadComplianceReport(complianceId, index);
                }}
              />
            );
        })}

      {report && tab === "External Scan" && (
        <ExternalScanTab
          scan={scan}
          reportItems={report}
          selectedSeverity={severityFilter}
          onNextClick={onNextClick}
          onPreviousClick={onPreviousClick}
          onSeverityClick={onSeverityClick}
          previousButtonDisabled={previousButtonDisabled}
          nextButtonDisabled={nextButtonDisabled}
          onFocusReportItem={(reportItem: ReportItem) =>
            setSelectedReportItem(reportItem)
          }
        />
      )}

      {report &&
        tab === "Code Scan" &&
        scan.Targets.map((target, index) => {
          if (target.Type === "CodeScan")
            return (
              <CodeScanTab
                scan={scan}
                title={`Target ${index + 1}`}
                onCodeScanClick={(index) => {
                  downloadCodescanReport(index);
                }}
              />
            );
        })}
    </>
  );
}
