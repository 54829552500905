import React from "react";
import { Scan, ReportItem } from "common";
import ResultTable from "./ResultTable";

interface Props {
  scan: Scan;
  reportItems: ReportItem[];
  selectedSeverity?: string;
  onNextClick: (last: ReportItem) => void;
  onPreviousClick: (previousReportItem: ReportItem) => void;
  onFocusReportItem: (reportItem: ReportItem) => void;
  onSeverityClick: (severity: any) => void;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
}

export default function ExternalScanTab({
  scan,
  reportItems,
  selectedSeverity,
  onSeverityClick,
  onNextClick,
  onPreviousClick,
  previousButtonDisabled,
  nextButtonDisabled,
  onFocusReportItem,
}: Props) {
  return (
    <>
    <div className="bg-slate-950 p-10 rounded-md">
      <h4 className="text-violet-300 text-2xl">Identified Public Endpoints</h4>
      <ul className="py-4 ">
        {scan.Targets.map((target, targetId) => {
          if (target.Type === "InfraScan" && target.PublicIps)
            return <>
            <h5 className="text-violet-200">Results in Target {targetId + 1}</h5>
            { (target.PublicIps as any[]).map(
              (publicIp: { region: string; domain: string }, index) => {
                const isEven = index % 2 === 0;
                const regionClass = isEven ? "text-violet-200 bg-gradient-to-bl from-violet-800 to-violet-950" : " text-blue-200 bg-gradient-to-bl from-slate-800 to-slate-950 border-gray-700 border-l-2 border-t-2 border-b-2";
                const domainClass = isEven ? "text-gray-200 border-violet-900 border-r-2 border-t-2 border-b-2" : "text-blue-200 bg-gradient-to-l from-slate-800 to-slate-950 border-gray-700 border-r-2 border-t-2 border-b-2";

                return (
                  <li className="text-white flex w-fit " key={index}>
                    <div
                      className={`mt-4 ${regionClass} w-32 rounded-l-md text-center`}
                    >
                      {publicIp.region}
                    </div>
                    <div
                      className={`px-3 mt-4 ${domainClass} w-fit rounded-r-md text-center`}
                    >
                      {publicIp.domain}
                    </div>
                  </li>
                );
              }
            )}
            </>
            
        })}
      </ul>
      </div>
      <ResultTable
        scan={scan}
        reportItems={reportItems}
        selectedSeverity={selectedSeverity}
        onSeverityClick={onSeverityClick}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        previousButtonDisabled={previousButtonDisabled}
        nextButtonDisabled={nextButtonDisabled}
        onFocusReportItem={onFocusReportItem}
      />
    </>
  );
}
